/**
 * Wrodeo game JS
 */
(function($) {

  let resetBoard = false;

  //Object containing all of the puzzle elements
  let elems = {
    input: $('#userWord'),
    submit: $('#userWordBtn'),
    form: $('#search-form'),
    feedback_container: $('.input-feedback'),
    searchWrapper: $('#search-wrapper'),
    keyboardWrapper: $('.keyboard-wrapper'),
    keyboard: null,
    phraseDiv: $('#phrase'),
    introDiv: $('#intro'),
    announceDiv: $('#announce'),

    welcomeModal: $('#welcome'),
    welcomeModal_start_btn: $('button#welcome-modal-start-btn'),

    tries_modal: {
      tries_count: $('strong.tries-count'),
      found_count: $('span.words-found'),
      tries_words: $('div.tries-words'),
      context_based: $('#tries-modal .context-based'),
    },
    hints_modal: {
      hints_count: $('p.hints-modal-progress .hints-count'),
      hints_total: $('p.hints-modal-progress .hints-total'),
      hints_left: $('p.hints-modal-progress .hints-left'),
      hints_words: $('.hints-words'),
    },
    stats_modal: {
      completed_value: $('div.stats-container .completed .stat-value'),
      completed_found: $('div.stats-container .completed .found'),
      completed_unique: $('div.stats-container .completed .unique'),
      tries_value: $('div.stats-container .tries .stat-value'),
      tries_success_rate: $('div.stats-container .tries .success-rate'),
      hints_value: $('div.stats-container .hints .stat-value'),
      hints_remaining: $('div.stats-container .hints .hints-remaining'),
      context_based: $('#stats-modal .context-based'),
    },
    settings_modal: {
      reset_btn: $('#reset'),
      surrender_btn: $('#surrender'),
      modal: $('#settings')
    },
    winner_modal: {
      level: $('.winner-modal-level'),
      success_rate: $('.success-rate'),
      success_rate_tries: $('.success-rate-tries'),
      hints_used: $('.hints-used'),
      hints_available: $('.hints-available'),
      modal: $('#wwcd'),
    },

    buttonDifficulty: $('ul.difficulty-level button'),

    date: $('#date'),
    date_setting: $('#date-setting-modal'),
    date_cta: $('#date-cta-modal'),
    
    version: $('#version'),
    version_setting: $('#version-setting-modal'),
    version_cta: $('#version-cta-modal'),

    twitter:  $('.btn-twitter'),
    facebook:  $('.btn-facebook'),
    telegram:  $('.btn-telegram'),
    tumblr:  $('.btn-tumblr'),
    
    support_twitter:  $('.btn-support-twitter'),
    support_facebook:  $('.btn-support-facebook'),
    support_telegram:  $('.btn-support-telegram'),
    support_tumblr:  $('.btn-support-tumblr'),
    
    gif: $('#gif'),
    
    buy_amazon: $('#buy-amazon'),

    tries_count: $('#tries-count'),
    hints_count: $('#hints-count'),
    stats_count: $('#stats-count'),

  };


  let game = {
    guessHistory: [],
    guessArray: [],
    guessRatio: 0,
    correctArray: [],
    cheatHistory: [],
    cheatCount: 0,
    nonword: '?',//placeholder for identifying a word that is not in our dictionary
    gameData: {  //array to store all of our game data
      'storage': '',
      'date': get_date(),
      'puzzle': '',
      'phrase': '',
      'words': [],
      'stripped': [],
      'level': '',
      'guesses': [],
      'cheats': [],
      'cheattracker': [],
      'board': '',
      'author': '',
      'title': '',
      'media': '',
      'blanks': '',
      'hints': '',
      'state': '',
      'version': '',
      'titleLink': '',
      'authLink': '',
      'player': '',
      'id': '',
    },
    playerStats: {  //array to store player stats
      'played': 0,
      'completed': 0,
      'avgAccuracy': 0,
      'streak': 0,
      'maxStreak': 0,
      'avgCheats': 0
    },
    giphy: '<iframe src="https://giphy.com/embed/l4q7TIW8nEZYOJUf6" width="480" height="320" frameBorder="0" class="giphy-embed" allowFullScreen></iframe><p><a href="https://giphy.com/gifs/studiosoriginals-l4q7TIW8nEZYOJUf6">via GIPHY</a></p>',
    clean: /[\d+\u00ad\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\.\/:;<=>?@\[\]^_`{|}~]/g, //regex to clean words
    wrodeoStats: localStorage.getItem('wrodeoStats'),
    wrodeo: localStorage.getItem('wrodeo'),  //grab the puzzle from local storage if its there
  };
  let cheat_tracker; //used to track cheats throughout the game.



  /**
   * Set up the game
   */
  function init_game () {

    //Load the keyboard HTML and initialise if using a touch device
    init_keyboard();

    //Set the focus on the main input
    elems.input.focus();

    //Set player stats
    set_player_stats();

    //Save stats
    save_stats();

    //Set game data:
    set_game_data();

    //Save the game
    save_game(game.gameData);

    //Set the UI based on gameData
    set_ui();
    
    //set the support sharing links
    set_support_share_links();
    
    //set the support buy link
    set_buy_link();

    //Update the local cheat_tracker var
    if (game.gameData.cheattracker) {
      update_local_cheat_tracker(game.gameData.cheattracker);
    }

    //Special case - check if the status is solved and handle
    if (game.gameData.state === 'solved') {
      load_game_solved();
    }//end if is solved

  }//end init_game

  
  //PREVIEW - DISABLE THIS WHEN DONE WITH PREVIEW
  /**
   * Get cookie - check a cookie value based on key 
   */
  function get_cookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }
  /**
   * Check preview - classic vs preview UX. DISABLE THIS WHEN DONE WITH PREVIEW
   */
  function check_preview () {
    let hostname = window.location.hostname
    let preview_ux = get_cookie("wrodeoPreview");

    if ((!(preview_ux) || (preview_ux=='')) && (hostname.includes("dev"))) {
      console.log("redirecting to classic wrodeo");
      window.location.replace("https://wrodeo.com"); 
    }
  } //end check preview
  //END PREVIEW
  


  /**
   * Set the game data - could be from saved (wrodeo obj), or set new game
   */
  function set_game_data () {

    //Check if we have a wrodeo object (local storage):
    if (game.wrodeo !== 'undefined' && game.wrodeo !== null) {

      let wrodeo_data = JSON.parse(game.wrodeo);

      // game.gameData.date = date; We already set the date on game init:

      //Set the level for the AJAX call. This may not have been set and we need it to retrieve the puzzle. Use game.wrodeo stored data for this.
      game.gameData.level = wrodeo_data.level;

      //Call get puzzle - AJAX call, retrieve game data and savePuzzle
      get_puzzle();

      if ((wrodeo_data.date !== game.gameData.date) || (wrodeo_data.version !== game.gameData.version) || (wrodeo_data.level !== game.gameData.level)) {  //if there is a new puzzle or version, or a different level
        console.log("new puzzle, version, or level found.  updating to latest");  //TESTING
        game.gameData.state = 'open';
      } else {  //load the saved puzzle
        game.gameData = wrodeo_data;  //load the stored values into game data
      }

    } else { //No saved game, set new

      game.gameData.date = date;  //today's date
      game.gameData.level = 'normal';  //default level is on normal
      game.gameData.state = 'open';  //game has started
      game.playerStats.streak = 0;  //reset player streak

      get_puzzle();  //get today's puzzle data

      elems.welcomeModal.modal('show');  //show the "how to play" modal

      save_stats();  //save player stats - we've reset the streak value
    }

  }//end set_game_data


  /**
   * Check the localStorage wrodeoStats obj. If set, then set the playerStats to the matching values.
   */
  function set_player_stats () {

    if (game.wrodeoStats) {
      let stats = JSON.parse(game.wrodeoStats);

      game.playerStats.played = stats.played;
      game.playerStats.completed = stats.completed;
      game.playerStats.avgAccuracy = stats.avgAccuracy;
      game.playerStats.streak = stats.streak;
      game.playerStats.maxStreak = stats.maxStreak;
      game.playerStats.avgCheats = stats.avgCheats;
    }

  }//end set_player_stats


  /**
   * saveStats::, save the player stats to local storage
   */
  function save_stats() {
    localStorage.setItem("wrodeoStats", JSON.stringify(game.playerStats));
  }//end game.playerStats


  /**
   * Save gameData local storage
   * @param gameData
   */
  function save_game(gameData) {
    localStorage.setItem("wrodeo", JSON.stringify(gameData));
  }//end save_game


  /**
   * Setup the UI based on the gameData (i.e. level selector vals)
   */
  function set_ui () {

    //set the intro div
    set_intro();

    //Set the unique words left count
    set_unique_left();

    //Set hints
    set_hints_stats();

    //Set the date in footer
    set_date();

    //Set the version in the footer
    set_version();

    //Build the board
    build_board();

    //Set the guesses
    set_guesses();

    //Update the success rate - game.guessRatio
    update_success_rate();

    //Set the cheats data
    set_cheats();

    //Update the UI - header and modals content
    update_ui();


  }//end set_ui


  /**
   * Generate puzzle board
   */
  function build_board() {

    //blank the phrase container
    elems.phraseDiv.html('');

    if (game.gameData.board) {  //if we found a stored board

      //Disable and update placeholder
      if (game.gameData.state === 'surrender') {  //if the game was already surrendered
        elems.input.prop('disabled', true).attr('placeholder', 'Surrendered!');
        elems.submit.prop('disabled', true).addClass('disabled');
      } else if (game.gameData.state === 'solved') {
        elems.input.prop('disabled', true).attr('placeholder', 'Puzzle Solved!');
        elems.submit.prop('disabled', true).addClass('disabled');
      }

      //replace the div with the existed stored board
      elems.phraseDiv.html(game.gameData.board);

      //Remove active classes from words
      (game.gameData.words).forEach((item, index) => {
        $('#'+index).removeClass('highlight_found', 'highlight_exsits');
      });

    } else {

      //Set the game board
      elems.phraseDiv.html(game.gameData.puzzle);

    }

    //update the gameboard
    game.gameData.board = elems.phraseDiv.html();

    //save it
    save_game(game.gameData);

  }//end build_board


  /**
   * getPuzzle::, AJAX call to get the current puzzle
   */
  function get_puzzle() {
    $.ajax({
      type: "GET",
      url: site_url+'/dailypuzzle.php?level=' + game.gameData.level,
      datatype: "json",
      async: false,
      success: save_puzzle,
    });
  }//end getPuzzle


  /**
   * savePuzzle::, received JSON data and parses it
   *
   * Set / update gameData
   *
   */
  function save_puzzle(response) {
    let wrodeoData = JSON.parse(response);  //JSON parse it
    game.gameData.id = wrodeoData.id;  //set the puzzle id
    game.gameData.storage = 'local';  //set storage location
    game.gameData.player = 'local';  //set player location
    game.gameData.puzzle = rtrim(ltrim(wrodeoData.board));  //get the formatted puzzle
    game.gameData.words = JSON.parse(rtrim(ltrim(wrodeoData.words)));  //array of words
    game.gameData.stripped = JSON.parse(rtrim(ltrim(wrodeoData.stripped)));  //array of cleaned words
    game.gameData.level = rtrim(ltrim(wrodeoData.level));  //the level
    game.gameData.author = rtrim(ltrim(wrodeoData.author));  //the author
    game.gameData.title = rtrim(ltrim(wrodeoData.title));  //the title
    game.gameData.media = rtrim(ltrim(wrodeoData.media));  //the media
    game.gameData.version = Number(rtrim(ltrim(wrodeoData.version)));  //the version
    game.gameData.titleLink = rtrim(ltrim(wrodeoData.titleLink));  //affiliate title link
    game.gameData.authLink = rtrim(ltrim(wrodeoData.authLink));  //affiliate author link
    game.gameData.blanks = Number(rtrim(ltrim(wrodeoData.blanks)));  //number of blanks
    game.gameData.hints = Number(rtrim(ltrim(wrodeoData.cheats)));  //number of hints
  }//end savePuzzle


  /**
   * Initialise and then show the keyboard for touch devices
   */
  function init_keyboard () {

    if ($('html').hasClass('touchevents')) {


      //Set the input to readonly to prevent tapping / focusing on the input
      elems.input.attr('readonly', 'readonly');


      let Keyboard = window.SimpleKeyboard.default;

      elems.keyboard = new Keyboard({
        onChange: input => onChange(input),
        onKeyPress: button => onKeyPress(button),
        physicalKeyboardHighlight: true,
        theme: "hg-theme-default",
        mergeDisplay: true,
        layoutName: "default",
        disableButtonHold: true,
        layout: {
          default: [
            "q w e r t y u i o p",
            "a s d f g h j k l",
            "{backspace} z x c v b n m {ent}"
          ]
        },
        display: {
          "{ent}": "Try",
          "{backspace}": "⌫",
        },
        buttonTheme: [
          {
            class: "wrodeo-keyboard-button",
            buttons: 'q w e r t y u i o p a s d f g h j k l {backspace} z x c v b n m {ent}'
          }
        ],
        onInit: function(){
          setTimeout(function () {
            $('body').css('padding-bottom', elems.searchWrapper.outerHeight());

            //fallback - set height once more to allow for complete rendering
            setTimeout(function () {
              $('body').css('padding-bottom', elems.searchWrapper.outerHeight());
            }, 2000);
          }, 150);
        }
      });


      /**
       * Update simple-keyboard when input is changed directly
       */
      document.querySelector(".input").addEventListener("input", event => {
        elems.keyboard.setInput(event.target.value);
      });


      function onChange(input) {
        document.querySelector(".input").value = input;
      }


      function onKeyPress(button) {
        if (button === "{ent}") {
          word_guess();
        }
      }

      elems.keyboardWrapper.removeClass('kb-hidden');
    }

  }//end init_keyboard


  /**
   * Set the unique words left val
   */
  function set_unique_left () {

    elems.stats_count.find('span.unique').html(get_unique_word_count());

  }//end set_unique_left

  /**
   * Set the intro
   */
  function set_intro () {

    let intro = "Today's Wrodeo is from the " +get_the_media()+' <strong>' + get_the_title() + '</strong> by ' + get_the_author() + '.'
    elems.introDiv.html(intro);
    
    //PREVIEW - DISABLE THIS WHEN DONE WITH PREVIEW
    
    let preview = "<strong>You are previewing Wrodeo's new design. <a href='contact.php'>Send feedback</a></strong>. ";
    //let button = '<strong>Too much change? <input id="return_classic" type="button" value="Switch to classic" /></strong>';
    let button = '<span>Switch <a href="#" id="return_classic">back to classic</a>.</span>';
    elems.announceDiv.html(preview+" "+button);
    
    document.getElementById("return_classic").onclick = function () {
      document.cookie = "wrodeoPreview=;domain=.wrodeo.com;path=/";
      window.open ('https://wrodeo.com','_self',false);
    };
    
    //END PREVIEW
    
  }//end set_intro

  

  /**
   * Set buy_link
   */
  function set_buy_link () {
    let theTitle = game.gameData.title;  //get the title
    if (game.gameData.titleLink) {  //if we have an affiliate link
      elems.buy_amazon.attr('onclick', "window.open('"+game.gameData.titleLink+"', '_blank');");
    }
  }//end set_buy_link
  

  /**
   * Set the hints stats (hints remaining)
   */
  function set_hints_stats () {
    elems.hints_count.html(game.gameData.hints);
  }//end set_hints_stats


  /**
   * Set the date in the footers
   */
  function set_date () {

    elems.date.html(game.gameData.id);  //set the puzzle date
    elems.date_setting.html(game.gameData.id);  //set the puzzle date
    elems.date_cta.html(game.gameData.id);  //set the puzzle date

  }//end set_date


  /**
   * Set the puzzle version in the footer
   */
  function set_version () {

    elems.version.html(game.gameData.version);  //set the puzzle version
    elems.version_setting.html(game.gameData.version);  //set the puzzle version
    elems.version_cta.html(game.gameData.version);  //set the puzzle version

  }//end set_version


  /**
   * Set the guesses table data
   */
  function set_guesses () {

    if (game.gameData.guesses.length > 0) {

      (game.gameData.guesses).forEach((item, index) => {  //loop through the guesses
        game.guessHistory.push([item[0], item[1], item[2]]);  //add the guess to our history array

        if (item[2] !== game.nonword) {  //if we haven't labeled it a non-word
          game.guessArray.push(item[1]);  //add the guess to our guess array
        }

        let strippedItem = item[1].toLowerCase().replace(game.clean, '');  //clean it up
        if ((game.gameData.stripped).includes(strippedItem)) {  //if it's a correct word, record it
          game.correctArray.push(strippedItem);  //put the item in the correct array
        }
      });//end fe gameData guesses

      //update our stats
      elems.tries_count.html(game.guessArray.length);

      set_unique_left();

    }//end if gameData guesses len

  }//end set_guesses


  /**
   * Set the cheats data
   */
  function set_cheats () {

    if (game.gameData.cheats) {
      (game.gameData.cheats).forEach((item, index) => {  //loop through the used hints
        game.cheatCount++;  //increase our cheat count
        game.cheatHistory.push([item[0], item[1]]);  //capture the cheats table
      });
    }

  }//end set_cheats


  /**
   * Update the guess ratio
   */
  function update_success_rate () {

    //if there is more than 1 guess (catch divide by zero). //calculate percentage
    if (game.guessArray.length !== 0) {
      game.guessRatio = (game.correctArray.length / game.guessArray.length) * 100;
    }

  }//end update_success_rate


  /**
   * Update the local cheat_tracker var as the users progresses through the game.
   * @param val
   */
  function update_local_cheat_tracker (val) {
    cheat_tracker = val;
  }//end update_local_cheat_tracker


  /**
   * Return the title with affiliate link if exists
   * @returns {string}
   */
  function get_the_title () {

    let theTitle = game.gameData.title;  //get the title
    if (game.gameData.titleLink) {  //if we have an affiliate link
      theTitle = '<a href="' + game.gameData.titleLink + '" target="_blank">' + game.gameData.title + '</a>'  //add the link to the title
    }

    return theTitle;
  }//end get_the_title


  /**
   * Return the author with affiliate link if exists
   * @returns {string}
   */
  function get_the_author () {

    let theAuthor = game.gameData.author;  //get the author
    if (game.gameData.authLink) {  //if we have an affiliate link
      theAuthor = '<a href="' + game.gameData.authLink + '" target="_blank">' + game.gameData.author + '</a>'  //add the lnk to the author
    }

    return theAuthor;

  }//end get_the_author

  /**
   * Return the media type
   * @returns {string}
   */
  function get_the_media () {
  
    let theMedia = game.gameData.media;  //get the media type
    
    return theMedia;
  }//end get_the_media

  /**
   * Get the puzzle date
   *
   *  - Get today's date
   *  - Get the day (pad it)
   *  - Get the month (start at 0 for Jan, pad it)
   *  - Get the year
   *  - Return formatted date
   *
   * @returns {string}
   */
  function get_date () {

    //get the puzzle date
    let today = new Date(),
        dd = String(today.getDate()).padStart(2, '0'),
        mm = String(today.getMonth() + 1).padStart(2, '0'),
        yyyy = today.getFullYear();  //the year

    return yyyy + mm + dd;  //format our date

  }//end get_date


  /**
   * Get the percentage of the puzzle completed
   * @returns String
   */
  function get_percent_complete () {

    let unique_words = get_unique_word_count(),
        percent = 0;  //zero percent;

    if (unique_words !== 0) {  //if there is more than 1 unique word (catch divide by zero)
      percent = (game.correctArray.length / unique_words) * 100;  //calculate percentage
    }

    return Math.round(percent) + '%';

  }//end get_percent_complete


  /**
   * Called from game_solved. Get and set random gif
   */
  function set_random_gif () {

      //get a random number for our gif
    let rando = Math.floor(Math.random() * 40) + 1;

    //update the gif
    elems.gif.attr('src', 'src/assets/gifs/' + rando + '.gif');

  }//end get_random_gif


  /**
   * Add the game stats to the social share links and init onClick events
   */
  function set_share_links () {

    //sharing links
    //sharing links
    let shareTextEmoji = "Wrodeo🤡 #" + game.gameData.id + ": " + Math.round(game.guessRatio) + "% | " + cheat_tracker.length + " hints | " + game.gameData.level + " 📚❤️",
        shareText = "Wrodeo #" + game.gameData.id + ": " + Math.round(game.guessRatio) + "% | " + cheat_tracker.length + " hints | " + game.gameData.level,
        shareURL = encodeURIComponent("https://wrodeo.com");

    elems.twitter.click(function(){
      window.open('https://twitter.com/share?url=' + shareURL + '&text=' + encodeURIComponent(shareTextEmoji) + '&hashtags=wrodeo,game,wrodeogame,wordnerd,wrodeoclown,literature,booklovers');
    });

    elems.facebook.click(function(){
      window.open('https://www.facebook.com/sharer/sharer.php?u=' + shareURL + '&quote=' + encodeURIComponent(shareText));
    });

    elems.telegram.click(function(){
      window.open('https://t.me/share/url?url=' + shareURL + '&text=' + encodeURIComponent(shareTextEmoji));
    });

    elems.tumblr.click(function(){
      window.open('https://www.tumblr.com/widgets/share/tool?canonicalUrl=' + shareURL + '&caption=' + encodeURIComponent(shareTextEmoji) + '&tags=wrodeo,game,wrodeogame,wordnerd,wrodeoclown,literature,booklovers');
    });

  }//end set_share_links
  
  /**
   * Add the game stats to the social share links and init onClick events
   */
  function set_support_share_links () {
  
    //support sharing links
    let shareTextEmoji = "I'm playing Wrodeo, a free-to-play literary-based word game. Can you complete the daily puzzle? 📚❤️", shareURL = encodeURIComponent("https://wrodeo.com");
  
    elems.support_twitter.click(function(){
      window.open('https://twitter.com/share?url=' + shareURL + '&text=' + encodeURIComponent(shareTextEmoji) + '&hashtags=game,literature,booklovers');
    });
  
    elems.support_facebook.click(function(){
      window.open('https://www.facebook.com/sharer/sharer.php?u=' + shareURL + '&quote=' + encodeURIComponent(shareText));
    });
  
    elems.support_telegram.click(function(){
      window.open('https://t.me/share/url?url=' + shareURL + '&text=' + encodeURIComponent(shareTextEmoji));
    });
  
    elems.support_tumblr.click(function(){
      window.open('https://www.tumblr.com/widgets/share/tool?canonicalUrl=' + shareURL + '&caption=' + encodeURIComponent(shareTextEmoji) + '&tags=game,literature,booklovers');
    });
  
  }//end set_support_share_links


  /**
   * Set the content, sharing links and show the winner modal
   */
  function load_game_solved () {

    set_share_links();

    //Load the stats into the winner modal:
    elems.winner_modal.level.html(game.gameData.level);
    elems.winner_modal.success_rate.html(Math.round(game.guessRatio)+'%');
    elems.winner_modal.success_rate_tries.html(game.guessArray.length);
    elems.winner_modal.hints_used.html(game.cheatCount);
    elems.winner_modal.hints_available.html(game.gameData.hints);

    elems.winner_modal.modal.modal('show');  //show the winner modal

  }//end load_game_solved


  /**
   * The game has been solved - fired by guess()
   *
   *  - Different to load_game_solved. That is to show the saved screen on pag reload.
   */
  function game_solved () {

    //mark the puzzle as solved
    game.gameData.state = 'solved';

    //capture the game board
    game.gameData.board = elems.phraseDiv.html();

    //save the game board
    save_game(game.gameData);

    //calculate avg accuracy
    game.playerStats.avgAccuracy = ((game.playerStats.avgAccuracy * game.playerStats.completed) + ((game.correctArray.length / game.guessArray.length) * 100)) / (game.playerStats.completed + 1);

    //calculate avg hints
    game.playerStats.avgCheats = ((game.playerStats.avgCheats * game.playerStats.completed) + cheat_tracker.length) / (game.playerStats.completed + 1);

    //calculate num completed
    game.playerStats.completed = game.playerStats.completed + 1;

    //calculate player streak
    game.playerStats.streak = game.playerStats.streak + 1;

    //if it's a new streak - record it
    if (game.playerStats.streak > game.playerStats.maxStreak) {
      game.playerStats.maxStreak = game.playerStats.streak;
    }

    //save the player stats
    save_stats();

    //set gif
    set_random_gif();

    //Make sure the plausible object exists
    if (typeof plausible !== 'undefined') {
      plausible('Solved', {
        props: {
          level: game.gameData.level,
          accuracy: Math.round(game.guessRatio),
          guesses: game.guessArray.length,
          hints: game.cheatCount
        }
      });  //track that a puzzle was completed
    }

    elems.input.prop('disabled', true).attr('placeholder', 'Puzzle Solved!');
    elems.submit.prop('disabled', true).addClass('disabled');

    set_share_links();

    //Load the stats into the winner modal:
    elems.winner_modal.level.html(game.gameData.level);
    elems.winner_modal.success_rate.html(Math.round(game.guessRatio)+'%');
    elems.winner_modal.success_rate_tries.html(game.guessArray.length);
    elems.winner_modal.hints_used.html(game.cheatCount);
    elems.winner_modal.hints_available.html(game.gameData.hints);

    //show the winner modal
    setTimeout(function () {
      elems.winner_modal.modal.modal('show');
    }, 1500);

  }//end game_solved


  /**
   * guess::, take the user input and check if it matches any words in the phrase.
   * highlight matches
   */
  function word_guess() {

    //clear the keyboard input
    if (elems.keyboard !== null) {
      elems.keyboard.clearInput();
    }

    elems.input.focus();  //return focus to the input box

    let inputVal = elems.input.val().trim();  //get the user word
    (game.gameData.words).forEach((item, index) => {  //brute force remove any highlight artifacts
      let element = document.getElementById(index);  //get the element
      element.classList.remove("highlight_found");  //remove found highlight
      element.classList.remove("highlight_exsits");  //remove exists highlight
    });


    if ((inputVal !== "") && (game.gameData.state !== 'solved') && (game.gameData.state !== 'surrender')) {  //if it's a game still in progress

      //strip all punctuation from the input word
      let strippedInput = inputVal.toLowerCase().replace(game.clean, ''),
          count_found = 0;//add to guess history

      let isword = false;  //assume it's not a correctly spelled word. This flag is a hack to accommodate our bolt-on dictionary


      //Used to identify a correct word, but not in puzzle
      let not_found = false;

      //check if this is a new word. compare stripped versions
      if ((!game.correctArray.includes(strippedInput)) && (!game.guessArray.includes(strippedInput))) {

        //Our dictionary
        if ((game.gameData.stripped).includes(strippedInput)) {  //it's a word in our puzzle so the user input must be a real, correctly spelled word
          isword = true;  //flag that it's a real word
        } else {  //the word isn't in the puzzle, look it up to see if it's spelled correctly

          not_found = true;

          try {
            if (check_dictionary(strippedInput)) {  //our dictionary says it's a word
              isword = true;  //flag that it's a real word
            }
          } catch (err) {  //if the dictionary is not available for some reason
            console.log("cannot get the dictionary");
            isword = true;  //fail open by flagging that it's a real word
          }

          //If it is a word (found in dictionary), but not in the puzzle:
          if (isword) {

            //Show the input feedback message
            show_input_feedback('not-found', inputVal);

            elems.submit.addClass('wobble');
            setTimeout(function () {
              elems.submit.removeClass('wobble');
            }, 1000);

          }//end is word

        }//end else word is not in puzzle

        if (isword) {  //if our dictionary says it's a real word, proceed

          game.guessArray.push(inputVal);  //add the word to the guess array
          if (game.guessArray.length === 1) {  //this is the first guess so the user has started the puzzle
            game.gameData.state = 'started';  //indicate that the game has started
            save_game(game.gameData);  //save the game data
            game.playerStats.played = game.playerStats.played + 1;  //update their stats
            save_stats();  //save the player stats

            //Make sure the plausible object exists
            if (typeof plausible !== 'undefined') {
              plausible('Started', {props: {level: game.gameData.level}});  //record that a puzzle was started
            }
          }//end first guess, user has started puzzle


          //Update the word bank
          count_found = update_word_bank_table(true, strippedInput);

          //Show the input feedback message
          if (!not_found) {
            show_input_feedback('found', inputVal, count_found);
          }

        } //end is word
        else {
          count_found = update_word_bank_table(false, strippedInput);

          //Show the input feedback message
          show_input_feedback('misspelled', inputVal);

        }//end else is NOT a word

        //add the data to our guess history array
        game.guessHistory.push([game.guessArray.length, elems.input.val(), count_found]);
        game.gameData.guesses = game.guessHistory;  //save our guess history
        save_game(game.gameData);  //save the game

      } else if (!isword && game.guessArray.includes(strippedInput) && !game.correctArray.includes(strippedInput)) {//end new word found


        //Show the input feedback message
        show_input_feedback('not-found', inputVal);

        //Add some visual feedback that this is an incorrect word
        elems.submit.addClass('wobble');
        setTimeout(function () {
          elems.submit.removeClass('wobble');
        }, 1000);


      } else {
        //get the index number of the matches
        let matches = (game.gameData.stripped).map((e, i) => e === strippedInput ? i : undefined).filter(x => x !== undefined);
        matches.forEach((item, index) => {  //loop through the matches
          let element = document.getElementById(item);  //get the element
          element.classList.add("highlight_exsits");  //add a highlight
        });
      }//end //we already have this correct word. highlight it


      //update success rate
      update_success_rate();


      //Update UI
      update_ui();


      //if we have all the correct words
      if (game.correctArray.length >= get_unique_word_count()) {
        game_solved();
      }//end if correct arr >= unique word count

    }

    elems.input.val('');
  }//end guess


  /**
   * User clicks on a word to cheat / get a hint
   */
  function cheat(_this) {

    //return focus to the input box
    elems.input.focus();

    //if we have cheats to use
    if (game.cheatCount < game.gameData.hints) {

      //Get the word HTML:
      let this_html = _this.innerHTML;
      let html_asterisks = this_html.replace(/<span class="cheated">[a-zA-Z]+<\/span>/ig, '*');
      let asterisks_array = [];
      for (let i=0; i<html_asterisks.length; i++) {
        if (html_asterisks[i] === '*') {
          asterisks_array.push(i);
        }
      }

      //Get a copy of this word from the gameData - this is an array of the letters
      let word_letters = [...(game.gameData.words)[_this.id]];
      let cheated_letters = [];
      for (let i=0; i<word_letters.length; i++) {

        //If i is in asterisks array, then we've cheated to get this letter, add to the cheated_letters array
        if (asterisks_array.includes(i)) {
          cheated_letters.push({
            index: i,
            letter: word_letters[i]
          });
        }

      }//end for letters


      //Strip tags from the innerHTML. It may already have a cheated span, then cast word to array of letters
      const this_word_letters = [...this_html.replace(/(<([^>]+)>)/gi, "")];

      //Make sure the plausible object exists
      if (typeof plausible !== 'undefined') {
        plausible('Hinted', {
          props: {
            word: word_letters.flat().join(""),
            level: game.gameData.level,
            length: word_letters.length,
            blanks: count_blanks(this_word_letters)
          }
        });  //hinted words
      }//end plausible

      //reset our blanks array
      game.gameData.blanks = [];

      //loop through each letter in the word (string). Find an underscore. And push it to the gameData.blanks array
      for (let i = 0, len = this_word_letters.length; i < len; ++i) {
        //if the character is a underscore save the index number to our blanks array
        if (this_word_letters[i] === "_") {
          game.gameData.blanks.push(i);
        }
      }

      //If there are no blanks, do nothing
      if (game.gameData.blanks.length <= 0) {
        return;
      }//end no blanks, do nothing.


      //Get a random blank index from the gameData blanks array
      let r = game.gameData.blanks[Math.floor(Math.random() * game.gameData.blanks.length)];

      //The letter we'll add to the word
      let letter = word_letters[r];

      //Replace the blank in the word with the new letter
      this_word_letters[r] = letter;

      //Add this to our cheated_letters array:
      cheated_letters.push({
        index: r,
        letter: letter
      });

      //Build up the HTML for the updated word
      let updated_word = '';
      for (let i=0; i<this_word_letters.length; i++) {
        let this_letter = this_word_letters[i];
        for (let k in cheated_letters) {

          if (cheated_letters.hasOwnProperty(k)) {
            if (cheated_letters[k].index === i) {
              this_letter = '<span class="cheated">'+this_letter+'</span>';
            }
          }

        }

        updated_word += this_letter;
      }


      //Place the updated word on the board
      _this.innerHTML = updated_word;


      //increase our cheat count
      game.cheatCount++;

      //add the word to our cheat tracker
      cheat_tracker.push([_this.id, r]);

      //capture the cheats table
      game.cheatHistory.push([game.cheatCount, updated_word]);


      //update game data with cheat info
      game.gameData.cheattracker = cheat_tracker;


      //update game data with cheat history
      game.gameData.cheats = game.cheatHistory;


      //update game data with the board
      game.gameData.board = elems.phraseDiv.html();


      //Update the UI
      update_ui();


      //save the game data
      save_game(game.gameData);


    }//end if have cheats

  }//end cheat


  /**
   * Check word against dictionary. If exists? True Else False
   *
   *
   * @param word
   * @returns {boolean}
   */
  function check_dictionary(word) {

    let result = false;
    $.ajax({  //use dictionary api to check the work
      type: "GET",
      url: site_url+'/dictionary.php?w=' + word,
      datatype: "json",
      async: false,
      success: function (response) {
        if (response === '1') {
          result = 1;  //we know the word
        } else {
          result = 0;  //we don't know this word
        }
      },
      error: function (response) {  //fail open
        result = 1;
      },
      fail: function (response) {  //fail open
        result = 1;
      }
    });

    return result
  }//end checkDictionary


  /**
   * ltrim::, trim whitespace on left
   */
  function ltrim(str) {
    if (!str) return str;  //if it's not a string, return
    return str.replace(/^\s+/g, '');  //replace whitespace at start
  }//end ltrim


  /**
   * rtrim::, trim whitespace on right
   */
  function rtrim(str) {
    if (!str) return str;    //if it's not a string, return
    return str.replace(/\s+$/g, '');  //replace whitespace at end
  }//end rtrim


  /**
   * Get a count of unique words
   * @returns {number}
   */
  function get_unique_word_count () {
    let str = (game.gameData.stripped).flat().join(" ");
    let set = new Set(str.split(' '));  //split the string then create a set
    return set.size;  //sets will have the unique words. get the size
  }//end get_unique_word_count

  /**
   * Get a count of total words
   * @returns {number}
   */
  function get_total_word_count () {
    return (game.gameData.stripped).length;  //length of our word array
  }//end get_total_word_count

  /**
   * Get a count of total hints
   * @returns {number}
   */
  function get_total_hint_count () {
    return (game.gameData.hints);  //hints
  }//end get_total_hint_count


  /**
   * Update the header stats and tries modal found and # of tries
   * Set unique left
   * Update completed stats
   * Refresh board word classes
   *
   *  - Called from guess() if we have a word
   *
   */
  function update_word_bank_table (is_word, strippedInput) {

    let count_found = 0;
    if (!is_word) {
      count_found = game.nonword;
    } else {

      //reset our found-word counter
      count_found = 0;

      //if the input word is in our word array
      if ((game.gameData.stripped).includes(strippedInput)) {

        //log that its a correct word
        game.correctArray.push(strippedInput);

        //update_completed_stats();

        //how many times is this word used in the phrase
        count_found = (game.gameData.stripped).filter(i => i === strippedInput).length;

        //get the index number of the matches
        let matches = (game.gameData.stripped).map((e, i) => e === strippedInput ? i : undefined).filter(x => x !== undefined);
        matches.forEach((item, index) => {  //loop through the matches

          let element = document.getElementById(item);  //get the element
          let $this = $('#'+item);


          //Set the width of the word before removing all blanks to prevent shifting:
          $this.css({'min-width': $this.outerWidth()+'px'});

          $this.removeClass('em-blanks').addClass('em-normal done highlight_found');

          //Remove the highlight class after the transition has completed
          setTimeout(function () {
            element.classList.remove("highlight_found");
            $this.removeClass('highlight_found');
          }, 2000);


          let cheater = [];  //create a cheater array
          for (let i = 0, len = cheat_tracker.length; i < len; ++i) {  //check our cheat array for any matches
            if (element.id == cheat_tracker[i][0]) {  //if there is a match
              cheater.push(cheat_tracker[i][1]);  //add it to cheater array
            }
          }

          if (cheater.length > 0) {  //if there are any cheats
            const fullWord = [...(game.gameData.words)[item]];  //copy the word

            for (let i = 0, len = cheater.length; i < len; ++i) {  //loop through the letters in the word
              fullWord[cheater[i]] = '<span class="cheated">' + fullWord[cheater[i]] + '</span>'  //highlight the cheated letter
            }

            //flatten the array back to a string
            element.innerHTML = fullWord.flat().join("");  //replace the blanks with the actual word with cheated hints

          } else {
            element.innerHTML = (game.gameData.words)[item];  //replace the blanks with the actual word
          }
          game.gameData.board = elems.phraseDiv.html();  //get the puzzle board

          save_game(game.gameData);  //save the game
        });
      }

    }//end is word

    return count_found;

  }//end update_word_bank_table


  /**
   * Change the game level - triggered by the main onscreen level select
   * @param level
   * @param newUser
   */
  function change_level (level, newUser = false) {

    //the stats are not already reset
    if (game.playerStats.played !== 0) {

      //If the has game started, remove current game form the stats
      if (game.guessArray.length > 0) {
        game.playerStats.played = game.playerStats.played - 1;
      }

      if (game.gameData.state === 'solved') {  //if the puzzle is already solved

        //calculate previous avg accuracy
        game.playerStats.avgAccuracy = ((game.playerStats.avgAccuracy * game.playerStats.completed + 1) - ((game.correctArray.length / game.guessArray.length) * 100)) / (game.playerStats.completed);

        //calculate previous avg hints
        game.playerStats.avgCheats = ((game.playerStats.avgCheats * game.playerStats.completed + 1) - cheat_tracker.length) / (game.playerStats.completed);

        //decrement the streak
        game.playerStats.streak = game.playerStats.streak - 1;

        //decrement the max streak
        game.playerStats.maxStreak = game.playerStats.maxStreak - 1;

        //decrement player completed stat
        game.playerStats.completed = game.playerStats.completed - 1;

      }

      //save the player stats
      save_stats();

    } else {  //the player stats are 0, go ahead and reset everything

      //reset all stats
      reset_stats(newUser);

    }

    //set the game level
    game.gameData.level = level;

    //save the game data
    save_game(game.gameData);

    //reset the puzzle
    reset_puzzle(newUser)

  }//end change_level


  /**
   * resetStats::, reset the player stats
   */
  function reset_stats(newUser = false) {

    //Make sure the plausible object exists
    if (typeof plausible !== 'undefined') {
      plausible('Reset Stats', {props: {level: game.gameData.level}});  //record that player stats were reset
    }

    //reset player stats
    game.playerStats.played = 0;
    game.playerStats.completed = 0;
    game.playerStats.avgAccuracy = 0;
    game.playerStats.streak = 0;
    game.playerStats.maxStreak = 0;
    game.playerStats.avgCheats = 0;

    //save player stats
    save_stats();

    if (!newUser) {
      window.location.reload();  //reload the window
    }

  }//end reset_stats


  /**
   * resetPuzzle::, reset the puzzle to the begining - Called from change_level
   */
  function reset_puzzle(newUser = false) {

    //Make sure the plausible object exists
    if (typeof plausible !== 'undefined') {
      plausible('Reset Puzzle', {props: {level: game.gameData.level}});  //record there was a puzzle reset
    }

    //if the stats are not already reset
    if (game.playerStats.played !== 0) {

      //the game was started
      if (game.guessArray.length > 0) {
        //decrement the number of games played
        game.playerStats.played = game.playerStats.played - 1;
      }

      //the game was already solved
      if (game.gameData.state === 'solved') {

        //update stats to previous avg
        game.playerStats.avgAccuracy = ((game.playerStats.avgAccuracy * game.playerStats.completed + 1) - ((game.correctArray.length / game.guessArray.length) * 100)) / (game.playerStats.completed);

        //update stats to previous avg
        game.playerStats.avgCheats = ((game.playerStats.avgCheats * game.playerStats.completed + 1) - cheat_tracker.length) / (game.playerStats.completed);

        //decrement the player streak
        game.playerStats.streak = game.playerStats.streak - 1;

        //decrement the player max streak
        game.playerStats.maxStreak = game.playerStats.maxStreak - 1;

        //decrement the player completed games
        game.playerStats.completed = game.playerStats.completed - 1;
      }

      //save the player stats
      save_stats();

    } else {  //the player stats are 0, go ahead and reset everything

      //reset the player stats
      reset_stats(newUser);

    }//end else the player stats are 0, go ahead and reset everything

    if (!newUser) {
      //negate the version
      game.gameData.version = -1;

      //save the game data
      save_game(game.gameData);

      //reload the window
      window.location.reload();
    }
  }//end reset_puzzle


  /**
   * User surrenders
   */
  function surrender_game () {

    //close the model
    elems.settings_modal.modal.modal('toggle');

    //disable the input box and set input placeholder text
    elems.input.prop('disabled', true).attr('placeholder', 'Surrendered!');
    elems.submit.prop('disabled', true).addClass('disabled');

    //close the game
    game.gameData.state = 'surrender';

    //Make sure the plausible object exists
    if (typeof plausible !== 'undefined') {
      plausible('Surrender', {props: {level: game.gameData.level}});  //record that the puzzle was surrendered
    }

    //loop through the words
    for (let i = 0, len = (game.gameData.words).length; i < len; ++i) {

      //strip all punctuation from the input
      let strippedWord = (game.gameData.words)[i].toLowerCase().replace(game.clean, '');

      //if the word isn't already guessed
      if (!game.correctArray.includes(strippedWord)) {
        let element = document.getElementById(i);

        //remove some css
        element.classList.remove("em-blanks");

        //add some css
        element.classList.add("em-normal");

        //add a highlight
        element.classList.add("highlight_surrender");

        //add a highlight
        element.classList.add("surrendered");

        //add a highlight
        element.classList.add("done");

        //check our cheat array for any matches, if the word was hinted add it to the array
        let cheater = [];
        for (let x = 0, lenx = cheat_tracker.length; x < lenx; ++x) {
          if (element.id == cheat_tracker[x][0]) {
            cheater.push(cheat_tracker[x][1]);
          }
        }

        //if hints were used
        if (cheater.length > 0) {
          //get the word
          const fullWord = [...(game.gameData.words)[i]];

          for (var y = 0, leny = cheater.length; y < leny; ++y) {
            fullWord[cheater[y]] = '<span class="cheated">' + fullWord[cheater[y]] + '</span>'  //create a span
          }

          //save the word - replace the blanks with the actual word with cheated hints
          element.innerHTML = fullWord.flat().join("");

        } else {
          //replace the blanks with the actual word
          element.innerHTML = (game.gameData.words)[i];
        }
      }
    }

    //capture the game board
    game.gameData.board = elems.phraseDiv.html();

    save_game(game.gameData);

  }//end surrender_game


  /**
   * Delete all records from local storage. Called in if (reset_board) Unused
   */
  function reset_everything () {

    //remove main wrodeo data from local storage
    localStorage.removeItem("wrodeo");

    //remove wrodeo stats data from local storage
    localStorage.removeItem("wrodeoStats");

  }//end reset_everything


  /**
   * counts the number of blanks in a word
   * @param str
   */
  function count_blanks (str) {

    // Do nothing if no string passed
    if (!str) {
      return 0;
    }

    let letterCount = 0;
    const arr = [...str];
    for (let i = 0, len = arr.length; i < len; ++i) { //loop through the array
      if (is_blank(arr[i])) {
        letterCount++;
      }
    }
    return Number(letterCount);

  }//end count_blanks


  /**
   * Check if letter in word is a blank
   *
   * @param str
   * @returns {boolean|RegExpMatchArray|Promise<Response | undefined>|*}
   */
  function is_blank(str) {
    return str.length === 1 && str.match(/_/gu);
  }//end is_blank


  /**
   * Update the UI when something has changed, i.e. cheated, guessed etc.
   *
   * Will update:
   *
   *  - Header
   *    - Tries count
   *    - Cheats count
   *    - Stats (correct out of unique)
   *
   *  - Modals:
   *    - Tries modal:
   *      - # of tries
   *      - # words found
   *      - Guessed words
   */
  function update_ui () {

    //Set context based content - we'll show different wording in the modals based on the game status
    elems.tries_modal.context_based.each(function(){

      let $this = $(this);

      switch (true) {

        case ($this.hasClass('single-plural')):

          if ($this.data('control') === 'tries-count') {
            let text = (game.guessArray.length > 1 || game.guessArray.length <= 0) ? $this.data('plural') : $this.data('singular');
            $this.text(text);
          }

        break;

        case ($this.hasClass('swap')):

          //Game hasn't started
          if (game.gameData.state === 'open') {
            $this.text($this.data('game-open'));
          } else {
            $this.text($this.data('game-started'));
          }

        break;

        case ($this.hasClass('hide')):

          if (game.gameData.state === 'open') {
            $this.hide();
          } else {
            $this.show();
          }

        break;

      }//end switch true


    });
    elems.stats_modal.context_based.each(function(){

      // data-game-status="open"
      let $this = $(this);
      if ($this.hasClass('hide')) {

        if (game.gameData.state === 'open') {
          $this.hide();
        } else {
          $this.show();
        }

      } else if ($this.hasClass('single-plural')) {
        if ($this.data('control') === 'stat-label-words-tried') {
          let text = (game.guessArray.length !== 1) ? $this.data('plural') : $this.data('singular');
          $this.text(text);
        }
        
        if ($this.data('control') === 'stat-label-hints-used') {
          let text = (game.cheatCount !== 1) ? $this.data('plural') : $this.data('singular');
          $this.text(text);
        }

        if ($this.data('control') === 'stat-label-hints-remaining') {
          let hints_left = (game.gameData.hints - game.cheatCount);
          let text = (hints_left !== 1) ? $this.data('plural') : $this.data('singular');
          $this.text(text);
        }

      }

    });

    //Set the difficulty level active class:
    $('ul.difficulty-level button.'+game.gameData.level).addClass('active');

    //Set the number of tries in the header
    elems.tries_count.html(game.guessArray.length);

    //Set the correct found in the header
    let words_left = (get_unique_word_count() - game.correctArray.length);
    //elems.stats_count.find('span.found').html(game.correctArray.length);
    elems.stats_count.find('span.found').html(words_left);

    //Set the tries modal found count:
    elems.tries_modal.found_count.html(game.correctArray.length);

    //Set the number of guesses
    elems.tries_modal.tries_count.html(game.guessArray.length);

    //Set the words tried to the tries modal
    let guess_array = JSON.parse(JSON.stringify(game.guessArray));
    guess_array.sort((a, b) => a.localeCompare(b, { sensitivity: 'base' }));

    let guess_html = '';
    for (let i=0; i<guess_array.length; i++) {
      if (game.correctArray.length && game.correctArray.includes(guess_array[i].toLowerCase())) {
        guess_html += '<li><span class="word em-normal highlight_found">'+guess_array[i]+'</span></li>';
      } else {
        guess_html += '<li><span class="word em-blank">'+guess_array[i]+'</span></li>';
      }
    }

    if (guess_html.length) {
      elems.tries_modal.tries_words.html('<ul class="modal-words">'+guess_html+'</ul>');
    }

    //Cheat count in the header
    elems.hints_count.html((game.gameData.hints - game.cheatCount));

    //Cheat count in the modal:
    elems.hints_modal.hints_count.html(game.cheatCount);

    //Total available cheats in modal:
    elems.hints_modal.hints_total.html(game.gameData.hints);

    //Hints remaining in modal:
    elems.hints_modal.hints_left.html((game.gameData.hints - game.cheatCount));

    //Add the cheated words to the modal container. Get them from the gameboard so we know they're up to date:
    let words = elems.phraseDiv.find('.word'),
        hints_html = '';
    words.each(function(){
      let $this = $(this);
      if ($this.find('.cheated').length) {
        hints_html += '<li><span class="word em-blank">'+$this.html()+'</span></li>';
      }

      if (hints_html.length) {
        elems.hints_modal.hints_words.html('<ul class="modal-words">'+hints_html+'</ul>');
      }
    });


    //Update the stats modal:
    elems.stats_modal.completed_value.html(get_percent_complete());
    //elems.stats_modal.completed_found.html(game.correctArray.length);
    elems.stats_modal.completed_found.html(words_left);
    elems.stats_modal.completed_unique.html(get_unique_word_count());
    elems.stats_modal.tries_value.html(game.guessArray.length);
    elems.stats_modal.tries_success_rate.html(Math.round(game.guessRatio)+'%');
    elems.stats_modal.hints_value.html(game.cheatCount);
    elems.stats_modal.hints_remaining.html((game.gameData.hints - game.cheatCount));


  }//end update_ui


  /**
   * Show a feedback message each time a word is entered
   * @param context
   * @param message_content
   * @param count
   * @returns {boolean}
   */
  function show_input_feedback (context, message_content, count) {

    if (!context.length) {
      return false;
    }

    //Clear any active classes
    elems.feedback_container.removeClass('found not-found misspelled active');

    let msg = '';
    switch (context) {

      case 'found':
        let times = (count > 1) ? 'times' : 'time';
        msg = '\''+message_content+'\' found '+count+' '+times;
      break;

      case 'not-found':
        msg = '\''+message_content+'\' not found';
      break;

      case 'misspelled':
        msg = 'I don\'t know the word \''+message_content+'\'';
      break;

    }//end sw context

    elems.feedback_container.find('p').text(msg);
    elems.feedback_container.addClass(context+' active');
    setTimeout(function () {
      elems.feedback_container.removeClass(context+' active');
    }, 2000);


  }//end show_input_feedback




  $(function() {
  
    check_preview();  //PREVIEW CHECK
  
  
    //Initialise the game
    init_game();

    //Rotate device icon animation
    setInterval(function () {
      $('img.rotate-img').toggleClass('rotate')
    }, 1500);


    //Form submit
    elems.form.submit(function(e){

      e.preventDefault();

      word_guess();

      return false;

    });


    //Guess:
    elems.submit.click(function(){
      if (!$(this).hasClass('disabled')) {
        word_guess();
      }
    });


    //Difficulty button click
    elems.buttonDifficulty.click(function(e){

      e.preventDefault();
      let $this = $(this);

      change_level($this.data('level'));

      return false;

    });


    //Reset
    elems.settings_modal.reset_btn.click(function(){
      reset_puzzle();
    });


    //Surrender
    elems.settings_modal.surrender_btn.click(function(){
      surrender_game();
    });


    //Welcome modal button click
    elems.welcomeModal_start_btn.click(function(){
      reset_puzzle();
    });


    //Word clicks:
    $(document).on('click', '.word', function(){
      cheat(this);
    });


  });//end DOM loaded





})(jQuery); // Fully reference jQuery after this point.
























